import { Controller } from '@hotwired/stimulus'
import { templateConverter } from '../lib/template_converter'

export default class extends Controller {
  static targets = [
    'checkbox',
    'checkboxList',
    'searchInput',
    'badgeTemplate',
    'selectResult',
    'statusTextAdded',
    'statusTextToAdd',
    'statusTextToRemove',
    'statusTextAvailable'
  ]

  static classes = ['status', 'pointer']

  initialize() {
    this.initialIds = []
  }

  checkboxListTargetConnected() {
    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.checked === true) {
        this.initialIds.push(checkbox.dataset.id)
      }
    })
  }

  toggle(event) {
    if (event.target.checked) {
      this.addBadge(event.currentTarget)
    } else {
      this.removeBadge(event.currentTarget)
    }
  }

  uncheck(event) {
    const checkbox = this.element.querySelector(`.checkbox-${event.currentTarget.dataset.id}`)
    const container = checkbox.closest('.select-badge-container')
    const statusContainer = container.querySelector('.select-list-element-status')
    checkbox.checked = false
    if (this.initialIds.includes(event.currentTarget.dataset.id)) {
      statusContainer.innerHTML = this.statusTextToRemoveTarget.innerHTML
    } else {
      statusContainer.innerHTML = this.statusTextAvailableTarget.innerHTML
    }
    statusContainer.classList.remove(...this.statusClasses)
    container.querySelector('label').classList.remove(this.pointerClass)
    event.currentTarget.parentElement.remove()
  }

  addBadge(target) {
    const badgeInnerHTML = templateConverter(this.badgeTemplateTarget.innerHTML,
      {
        id: target.dataset.id,
        text: target.dataset.text,
        color: target.dataset.color,
        texthover: target.dataset.texthover
      })
    const container = target.closest('.select-badge-container')
    const statusContainer = container.querySelector('.select-list-element-status')
    this.selectResultTarget.insertAdjacentHTML('beforeend', badgeInnerHTML)
    container.querySelector('label').classList.add(this.pointerClass)
    if (this.initialIds.includes(target.dataset.id)) {
      statusContainer.innerHTML = this.statusTextAddedTarget.innerHTML
    } else {
      statusContainer.innerHTML = this.statusTextToAddTarget.innerHTML
    }
    statusContainer.classList.add(...this.statusClasses)
  }

  removeBadge(target) {
    document.getElementById(`select-badge-${target.dataset.id}`).remove()
  }

  filter(event) {
    Array.from(this.checkboxListTarget.children).forEach((div) => {
      const a = div.getElementsByTagName('p')[0]
      const txtValue = a.textContent || a.innerText
      if (txtValue.toUpperCase().indexOf(event.currentTarget.value.toUpperCase()) > -1) {
        div.style.display = ''
      } else {
        div.style.display = 'none'
      }
    })
  }
}
