import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'buttonSubmit',
    'form',
    'recurlyElements',
    'recurlyThreeDSecure',
    'recurlyThreeDSecureActionResultTokenId',
    'error',
    'errorDefault',
    'errorFirstName',
    'errorLastName',
    'errorAddress1',
    'errorCity',
    'errorPostalCode',
    'errorCountry',
    'errorVatNumber',
    'errorNumber',
    'errorMonth',
    'errorYear',
    'errorCvv'
  ]

  static values = {
    apiPublicKey: String,
    i18nCvv: String,
    i18nExpiry: String,
    i18nNumber: String,
    i18nFields: Object,
    i18nErrors: Object
  }

  i18nErrorValueDefault = 'default'
  i18nErrorValue3dsFailed = '3ds_failed'
  errorTargetNames = this.errorTargets.map((errorTarget) => {
    return errorTarget.dataset.recurlyTarget
                              .split(' ')
                              .filter(error => error !== 'error')
                              .map(error => error + 'Target')
  }).flat()

  initialize() {
    if (typeof(window.recurly) === 'undefined') {
      return
    }

    if (!window.recurly.configured) {
      window.recurly.configure(this.apiPublicKeyValue)
    }

    this._attachRecurlyCardElement()
  }

  sendForm(event) {
    event.preventDefault()
    this._disableButtonSubmit()
    this._removeErrors()

    window.recurly.token(this.recurlyElements, this.formTarget, (error) => {
      if (!error) {
        return this.formTarget.requestSubmit()
      }

      error.details.forEach((errorDetail) => {
        const errorTarget = this._fieldToErrorTarget(errorDetail.field)
        const translatedField = this._translateField(errorDetail.field)

        errorDetail.messages.forEach((errorMessage) => {
          const translatedErrorMessage = this._translateErrorMessage(errorMessage)
          let translatedFullMessage = ''
          if (translatedField === undefined) {
            translatedFullMessage = error.message
          } else {
            translatedFullMessage = translatedField + ' ' + translatedErrorMessage
          }
          this._addError(errorTarget, translatedFullMessage)
        })
      })

      this._enableButtonSubmit()
    })
  }

  recurlyThreeDSecureTargetConnected() {
    if (this.recurlyThreeDSecureTarget.dataset.recurlyActionTokenId == null) {
      return
    }

    const risk = window.recurly.Risk()
    const threeDSecure = risk.ThreeDSecure({
      actionTokenId: this.recurlyThreeDSecureTarget.dataset.recurlyActionTokenId
    })

    threeDSecure.on('token', (token) => {
      this.recurlyThreeDSecureActionResultTokenIdTarget.value = token['id']
      this.formTarget.requestSubmit()
    })

    threeDSecure.on('error', () => {
      const translatedErrorMessage = this.i18nErrorsValue[this.i18nErrorValue3dsFailed]
      this._addError(this.errorDefaultTarget, translatedErrorMessage)
      this._enableButtonSubmit()
    })

    threeDSecure.attach(this.recurlyThreeDSecureTarget)
  }

  _attachRecurlyCardElement() {
    const cardElementOptions = {
      style: {
        placeholder: {
          content: {
            cvv: this.i18nCvvValue,
            expiry: this.i18nExpiryValue,
            number: this.i18nNumberValue
          }
        }
      }
    }

    this.recurlyElements = window.recurly.Elements()
    this.recurlyElements.CardElement(cardElementOptions).attach(this.recurlyElementsTarget)
  }

  _translateField(field) {
    return this.i18nFieldsValue[field]
  }

  _translateErrorMessage(errorMessage) {
    const i18nError = errorMessage.replace(/[^a-zA-Z0-9 ]/g, '').replace(/ /g, '_')

    if (i18nError in this.i18nErrorsValue) {
      return this.i18nErrorsValue[i18nError]
    } else {
      return this.i18nErrorsValue[this.i18nErrorValueDefault]
    }
  }

  _fieldToErrorTarget(field) {
    const targetName =  'error' + field.split('_').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1)
    }).join('') + 'Target'

    if (this.errorTargetNames.includes(targetName)) {
      return this[targetName]
    } else {
      return this.errorDefaultTarget
    }
  }

  _addError(errorTarget, errorMessage) {
    if (errorTarget.textContent !== '') {
      errorTarget.innerHTML += "<br/>"
    }

    errorTarget.innerHTML += errorMessage
  }

  _removeErrors() {
    this.errorTargets.forEach((errorTarget) => { errorTarget.innerHTML = '' })
  }

  _enableButtonSubmit() {
    this.buttonSubmitTarget.classList.add("cursor-pointer")
    this.buttonSubmitTarget.classList.remove("cursor-not-allowed")
    this.buttonSubmitTarget.disabled = false
  }

  _disableButtonSubmit() {
    this.buttonSubmitTarget.classList.add("cursor-not-allowed")
    this.buttonSubmitTarget.classList.remove("cursor-pointer")
    this.buttonSubmitTarget.disabled = true
  }
}
