import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['dialog']

  connect() {
    this.dialogTargets.forEach(dialog => {
      this.createTemplate(dialog)
    })
  }

  reset({ detail: { id } }) {
    const filteredId = this.dialogTargets.filter(dialog => dialog.id === id)
    if (filteredId.length === 0) {
      return
    }
    const template = document.getElementById(`template-${id}`)
    const dialog = document.getElementById(id)
    dialog.innerHTML = template.innerHTML
  }

  forceReset({ params }) {
    this.reset({ detail: { id: params.id } })
  }

  createTemplate(dialog) {
    const template = document.createElement('template')
    template.innerHTML = dialog.innerHTML
    template.setAttribute('id', `template-${dialog.id}`)
    dialog.parentNode.appendChild(template)
  }
}
