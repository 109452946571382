import { Controller } from '@hotwired/stimulus'

// @see https://www.donnfelker.com/disable-attachments-in-the-trix-editor/
export default class extends Controller {
  connect() {
    document.addEventListener('trix-initialize', function() {
      const fileTools = document.querySelector('.trix-button-group--file-tools')
      fileTools?.remove()
    })
    document.addEventListener('trix-attachment-add', function(event) {
      if (!event.attachment.file) {
        event.attachment.remove()
      }
    })
    document.addEventListener('trix-file-accept', function(event) {
      event.preventDefault()
    })
  }
}
